<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">Job Titles</h2>
          </div>
          <div class="col text-end">
              <a class="btn btn-outline-dark me-2" href="/export/fields/jobtitle">Export</a>
              <button class="btn btn-outline-dark" >import</button>
          </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <div class="row t-layout">
          <div v-if="$store.state.permittedActions.includes(131)" class="col-lg-6 mb-3">
              <div class="card">
            <div class="card-header">

            </div>
            <div class="card-body">
                <flash-message class="myCustomClass"></flash-message>
                <form  @submit.prevent="onSubmit($event)">
                    <div class="mb-3">
                        <label class="form-label">Department</label>
                        <b-form-select
                            class="form-select"
                            aria-label="Default select example"
                            v-model="$v.form.dept_code.$model"
                            :validator="$v.form.dept_code"
                            :options="departments"
                        ></b-form-select>
                        <span
                            class="error "
                            v-if="$v.form.dept_code.$error == true"
                            >Please select department
                        </span>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Job Title</label>
                        <input
                            class="form-control"
                            v-model="$v.form.jobtitle.$model"
                            :validator="$v.form.jobtitle"
                        />
                        <span
                            class="error "
                            v-if="$v.form.jobtitle.$error == true"
                            >Job Title is required </span
                        >
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-outline-primary" >Save</button>
                    </div>
                </form>
            </div>
          </div>
          </div>
          <div :class="$store.state.permittedActions.includes(131) ? 'col-lg-6 mb-3': 'col-md-12 mb-3'">

            <div v-if="this.$store.state.loader">
                <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
            </div>
            <div v-else class="card">
            <div class="card-header">
            </div>
            <div class="card-body">

              <b-table
                class="table table-custom"
                :items="jobTitle"
                :fields="fields"
                show-empty
                sticky-header
              >
              <template v-slot:cell(jobTitle)="data">
                  {{ data.item.jobtitle }}
              </template>
              <template v-slot:cell(department)="data">
                  {{ data.item.department }}
              </template>
              <template v-slot:cell(action)="data">
                  <div class="d-inline-block">
                      <div class="d-flex justify-content-md-around">
                          <a v-if="$store.state.permittedActions.includes(132)" class="button button1" title="Edit" @click.prevent="deleteJobTitle(data.item.id)"><i class="icon-bin"></i></a>
                      </div>
                  </div>
              </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios'; 

export default {
   data(){
       return {
           isModalVisible: false,
           form: {
               jobtitle: null,
               dept_code: null
           },
           fields:[
               {
                   key: 'jobTitle',
                   label: 'Job Title',
                   tdAttr: { 'custom-label'  : "Job Title:"}
               },
               {
                key: 'department',
                   tdAttr: { 'custom-label'  : "Department:"}
               },
               {
                   key: 'action',
                   tdAttr: { 'custom-label'  : "Action:"}
               }
           ]
       }
   },
   validations: {
       form: {
           dept_code: {
               required
           },
           jobtitle: {
               required
           }
       }
   },
   computed:{
       departments(){
           return this.$store.state.jobTitleData.d.map((data) =>{
               return {
                    value: data.id,
                    text: data.department
               };
           });
       },
       jobTitle(){
           return this.$store.state.jobTitleData.data.map((data)=>{
               return {
                   id: data.id,
                   jobtitle: data.jobtitle,
                   department: this.$store.state.jobTitleData.d.filter((department) => { return department.id == data.dept_code})[0].department
               }
           });
       }
   },
   created(){
       this.$store.dispatch('switchLoader', true);
       this.$store.dispatch('loadJobTitleData');
   },
  
   methods:{
       
       onSubmit(event){
            this.$v.$touch();
            if (this.$v.$invalid) return;
            axios
                .post("/fields/jobtitle/add", this.form)
                .catch(({ response }) => {
                this.flashMessage.error({
                    message: "Something went wrong!",
                    time: 3000,
                });
                })
                .then(({ data }) => {
                if (data.success) {
                    this.form.department = null;
                    this.flashMessage.success({
                    message: data.success,
                    time: 3000,
                    });
                    this.$store.dispatch('loadJobTitleData');
                } else {
                    this.flashMessage.error({
                    message: data.error,
                    time: 3000,
                    });
                }
                });
       },
       deleteJobTitle(id){
           if(confirm('Are you sure?')){
                axios.get("/fields/jobtitle/delete/"+id)
                    .catch(({ response }) => {
                    this.flashMessage.error({
                        message: "Something went wrong!",
                        time: 3000,
                    });
                    })
                    .then(({ data }) => {
                    if (data.success) {
                        this.form.department = null;
                        this.flashMessage.success({
                        message: data.success,
                        time: 3000,
                        });
                        this.$store.dispatch('loadJobTitleData');
                    } else {
                        this.flashMessage.error({
                        message: data.error,
                        time: 3000,
                        });
                    }
                    });
            }
       }
   }
}
</script>
